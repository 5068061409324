import {useEffect, useState, useCallback} from 'react'

export default function useWindowSize() {
    const isClient = typeof window === 'object';
  
    const getSize = useCallback(() => {
      return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
        // outerWidth: isClient ? window.outerWidth : undefined,
        // outerHeight: isClient ? window.outerHeight : undefined,
      };
    }, [isClient])
  
    const [windowSize, setWindowSize] = useState(getSize);
  
    useEffect(() => {
      if (!isClient) {
        return false;
      }
      
      function handleResize() {
        // console.log(getSize())
        setWindowSize(getSize()) //retorna el tamaño de pantalla actual
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [getSize, isClient]); // Empty array ensures that effect is only run on mount and unmount
  
    return windowSize;
}