import React, { useState } from 'react'
import Parser from 'html-react-parser';
import axios from 'axios'
// import PropTypes from 'prop-types'

function MSolicitarPartModal(props) {
    const {
        title,
        name,
        fc_DESCRIPCION,
        lstPlanContratacionxPlan,
    } = props
    const [callCond, setCallCond] = useState(false)
    const [alertCond, setAlertCond] = useState(false)
    const [phoneCode, setPhoneCode] = useState("+569")
    const [localTelefono_tipo, setLocalTelefono_tipo] = useState("")
    const [maxlengthPhone, setMaxlengthPhone] = useState(8)
    const [statusClass, setStatusClass] = useState('')
    const [localTelefono, setLocalTelefono] = useState('')
    const [errorField, setErrorField] = useState('')
    const [notification, setNotification] = useState('')
    const [inputDisabled, setInputDisabled] = useState(false)

    function clearClassStatusPlanComparador() {
        setStatusClass('')
        setErrorField('')
        setAlertCond(false)
        setInputDisabled(false)
        setLocalTelefono('')
    }
    function phoneTypeComparador(e) {
        let ItemphoneArea = e.target.value
        let phoneAreaCode = ItemphoneArea === "fijo" ? "+56" : "+569";
        setPhoneCode(phoneAreaCode)
        let maxlengthInput = ItemphoneArea === "fijo" ? 9 : 8;
        setMaxlengthPhone(maxlengthInput)
        let localTelefonotipo = ItemphoneArea === "fijo" ? "fijo" : "movil";
        setLocalTelefono_tipo(localTelefonotipo)
        clearClassStatusPlanComparador()
        setLocalTelefono('')
    }

    function validateOnlyNumbersComparador(e) {
        clearClassStatusPlanComparador()
        let regex = /^[0-9\b]+$/;
        let inputVal = e.target.value
        if (inputVal === '' || regex.test(inputVal)) {
            // console.log("numberssssssssssssssssssssss::::::" + inputVal)
            setLocalTelefono(inputVal)
        }
    }

    function errorSetDataStylePlanComparador(maxim = null, errorServer = null) {
        console.log("errorSetDataStylePlanComparador: ")
        let defaultError = "Ha ocurrido un error, vuelva a intentarlo"

        if (maxim) {
            setErrorField("El valor debe ser de " + maxim + " caracteres.")
            setStatusClass('error')
        }
        else {
            // if (errorServer != null) {
            if (errorServer === "Error en el servicio.") {
                defaultError = errorServer
            }
            setNotification(defaultError)
            // $(DivmSolicitar).find("[id=message]").text(defaultError);
        }
    }

    function magazineSubmitPlanComparador(c, urlsendData = null, PlanContratacionCAL) {
        clearClassStatusPlanComparador()
        console.log("magazineSubmitPlanComparador.......................")

        if (localTelefono.length < maxlengthPhone) {
            errorSetDataStylePlanComparador(maxlengthPhone)
            // setAlertCond(true)
        } else {
            console.log("sending...");
            sendDataPlan(urlsendData, PlanContratacionCAL);
            setInputDisabled(true)
            setAlertCond(true)
            setCallCond(false)
        }
    }

    function successSetDataStyleComparador() {
        setStatusClass('success')
        setNotification("Tus datos fueron inscritos correctamente.<br><strong>Pronto nos comunicaremos contigo.</strong>")
    }

    const searchCharacteristics = (search, lstPlanContratacionxPlan) => {

        const data = lstPlanContratacionxPlan.length ? lstPlanContratacionxPlan : [];
        const newCaractData = data.filter(function (PlanContra) {
            let lstPlan = []

            lstPlan = PlanContra.fc_OPCION_LBX
            // console.log("to search---------- TypeFlag:", lstPlan, search)
            return lstPlan === search
        })
        // console.log("to search---------- TypeFlag:", TypeFlag, search, newCaractData, newCaractData.length)
        return newCaractData

    }

    let PlanContratacionCAL = searchCharacteristics('CAL', lstPlanContratacionxPlan)
    let PlanContratacionCHV = searchCharacteristics('CHV', lstPlanContratacionxPlan)
    let PlanContratacionCLN = searchCharacteristics('CLN', lstPlanContratacionxPlan)

    // console.log("PlanContratacion FC_OPCION_LBXto search----------", PlanContratacionCAL, PlanContratacionCHV, PlanContratacionCLN)

    async function sendDataPlan(urlsendData = null, PlanContratacionCAL) {

        let urlAjaxsendData = "sendDataPersonas"
        if (urlsendData != null) {
            urlAjaxsendData = urlsendData;
        }


        var SendDataPersonasRequest = {
            localRut1: PlanContratacionCAL.fc_RUT_DEFAULT ? PlanContratacionCAL.fc_RUT_DEFAULT : "",
            localTelefono: localTelefono ? localTelefono : "",
            localServicio: PlanContratacionCAL.fc_SERVICIO ? PlanContratacionCAL.fc_SERVICIO : "",
            localSegmento: PlanContratacionCAL.fc_SEGMENTO ? PlanContratacionCAL.fc_SEGMENTO : "",
            localCliente: PlanContratacionCAL.fc_TIPOCLIENTE ? PlanContratacionCAL.fc_TIPOCLIENTE : "",
            localTelefono_tipo: localTelefono_tipo ? localTelefono_tipo : "",
            localProducto: PlanContratacionCAL.fc_PRODUCTO ? PlanContratacionCAL.fc_PRODUCTO : "",
            localCanal: PlanContratacionCAL.fc_CANAL ? PlanContratacionCAL.fc_CANAL : "",
            localId_c2c: PlanContratacionCAL.fc_ID_C2C ? PlanContratacionCAL.fc_ID_C2C : "",
            localmail: PlanContratacionCAL.fc_mail ? PlanContratacionCAL.fc_mail : "mail@mail.com",
        };

        var jsonSendData = JSON.stringify(SendDataPersonasRequest);
        console.log("Entramos en sendDataPersonas", urlAjaxsendData);
        console.log(jsonSendData);
        let url_envio_formulario = localStorage.getItem('url_envio_formulario')
        const configMagazineService = {
            method: 'POST',
            url: url_envio_formulario,
            data: jsonSendData,
            headers: {
                'Accept': "application/json",
                'Content-Type': "application/json"
            },
        };
        try {
            let response = await axios(configMagazineService)
            // clearClassStatusPlanComparador()
            successSetDataStyleComparador()
            console.log("response- configMagazineService------------------------->", response, response.data);
        } catch (error) {
            errorSetDataStylePlanComparador(null, error)
            setStatusClass('error')
            console.log("response- configMagazineService-------------------------> Error.....", error);
        }

        // setAlertCond(true)


        // ajax({
        //     url: "/magazineService/" + urlAjaxsendData,
        //     type: "POST",
        //     data: jsonSendData,
        //     dataType: "json",
        //     contentType: "application/json; charset=utf-8",
        //     success: function (data) {
        //         console.log(data, data.localMessage);
        //         if (data.localExecutionCode == 2 || data.localExecutionCode == 3 || data.localIdTrans == 0) {
        //             if (data.localIdTrans == 0) {
        //                 data.localMessage = 'Error en el servicio.'
        //             }
        //             // $(DivmSolicitar).find("[id=message]").text(data.localMessage);
        //             errorSetDataStylePlanComparador(null, data.localMessage)
        //             // errorSetDataStylePlan
        //             // $(DivmSolicitar).find("button[name='btn-tertiary-transp']").attr("style", "display: block ")
        //             // $(DivmSolicitar).find("a[name='btn-more-options']").attr("style", "display: block ")
        //             //set css error
        //         } else {
        //             //clear css error
        //             clearClassStatusPlan(index)
        //             successSetDataStylePlan(index)
        //         }
        //         // $(DivmSolicitar).find("input[name='localRut1']").prop("disabled", false);
        //         // $(DivmSolicitar).find("[id=localTelefono]").prop("disabled", false);
        //     },
        //     error: function (xhr, ajaxOptions, thrownError) {

        //         // $(DivmSolicitar).find("input[name='localRut1']").prop("disabled", false);
        //         // $(DivmSolicitar).find("[id=localTelefono]").prop("disabled", false);
        //         // $(DivmSolicitar).find("button[name='btn-tertiary-transp']").attr("style", "display: block ")
        //         // $(DivmSolicitar).find("a[name='btn-more-options']").attr("style", "display: block ")

        //         errorSetDataStylePlanComparador(null, xhr.status)
        //         console.log(thrownError);
        //         console.log(xhr.status);
        //     }
        // });
    }
    return (
        <div className="modalClaro mSolicitar">
            <div className="mSolicitarCont">
                {
                    title ?
                        <p className="mSolTitle">{title}:</p>
                        : null
                }
                <p className="mSolTitle">{name}</p>
                <div className="mSolBundle">
                    {
                        fc_DESCRIPCION.includes("Televi") || fc_DESCRIPCION.includes("televi")
                            ? <p><i className="ico-tv"></i><span>TV</span></p>
                            : null
                    }
                    {
                        fc_DESCRIPCION.includes("Internet") || fc_DESCRIPCION.includes("internet")
                            ? <p><i className="ico-tv"></i><span>Internet</span></p>
                            : null
                    }
                    {
                        fc_DESCRIPCION.includes("Telef") || fc_DESCRIPCION.includes("telef")
                            ? <p><i className="ico-tv"></i><span>Telefónia</span></p>
                            : null
                    }
                </div>
                {/* <p className="mSolDesc">{fc_DESCRIPCION}</p> */}
                {
                    !callCond && !alertCond ?
                        <div className="mSolSubs" id="mSolOptions">
                            <p>Selecciona una opcion de contratación:</p>
                            {
                                PlanContratacionCLN.length
                                    ?
                                    <a className="btn btnPrimario withIco" href={`${PlanContratacionCLN[0].fc_URL}`}>
                                        <i className="ico-mouse-pointer"></i>
                                        <span>Contrata Online</span>
                                    </a>
                                    : null
                            }
                            {
                                PlanContratacionCHV.length
                                    ?
                                    <a className="btn btnPrimario withIco" href={`${PlanContratacionCHV[0].fc_URL}`}>
                                        <i className="ico-message-circle"></i>
                                        <span>Chat de Ventas</span>
                                    </a>
                                    : null
                            }
                            {
                                PlanContratacionCAL.length
                                    ?
                                    <a className="btn btnPrimario withIco" id="callMeNowBtn" href="#!" onClick={() => setCallCond(c => !c)}>
                                        <i className="ico-phone"></i>
                                        <span>Llámenme Ahora</span>
                                    </a>
                                    : null
                            }
                        </div>
                        : <></>
                }
                {
                    callCond ?
                        <div className="mSolForm">
                            <p>Ingresa tu número de contacto:</p>
                            <form action="">
                                <fieldset>
                                    <span className="select big">
                                        <select onChange={phoneTypeComparador} disabled={inputDisabled}>
                                            <option value="movil">M&oacute;vil</option>
                                            <option value="fijo">Fijo</option>
                                        </select>
                                    </span>
                                </fieldset>
                                <fieldset>
                                    <div className={'inputPhone val ' + statusClass}>
                                        <span className="phoneCode">{phoneCode}</span>
                                        <div className="inputField">
                                            <input
                                                type="text"
                                                maxLength={maxlengthPhone}
                                                onChange={validateOnlyNumbersComparador}
                                                placeholder="Ingresa tu n&uacute;mero"
                                                value={localTelefono}
                                                disabled={inputDisabled}
                                            />
                                        </div>
                                    </div>
                                    <span className="errorField">{errorField}</span>
                                </fieldset>
                                <div className="mSolFormActions">
                                    {/* <button className="btn btnPrimario" type="submit" onClick={() => { setCallCond(c => !c); setAlertCond(c => !c); magazineSubmitPlanComparador(); }}>Enviar</button> */}
                                    <button className="btn btnPrimario" type="button" onClick={() => { magazineSubmitPlanComparador(c => !c, 'sendDataPersonas', PlanContratacionCAL[0]); }}>Enviar</button>
                                    <a className="textLink" id="mSolOptionsBtn" href="#!" onClick={() => { setCallCond(c => !c); clearClassStatusPlanComparador(); }}>Ver otras opciones</a>
                                </div>
                            </form>
                        </div>
                        : <></>
                }
                {
                    alertCond ?
                        <div className="c2cAlerts">
                            <div className={'notification ' + statusClass}>
                                <p>{Parser(notification)}</p><span className="ico-x"></span>
                            </div>
                            {/* <div className="notification error">
                                <p>{notification}</p><span className="ico-x"></span>
                            </div> */}
                            <a className="textLink" href="#!" onClick={() => { setCallCond(c => !c); setAlertCond(c => !c); clearClassStatusPlanComparador(); }}>Vuelve a enviarnos tu número</a>
                        </div>
                        : <></>
                }
            </div>
        </div>
    )
}

MSolicitarPartModal.defaultProps = {}

// MSolicitarPartModal.propTypes = {}

export default MSolicitarPartModal
