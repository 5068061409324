import React, { Fragment } from 'react'
import { useState, useEffect } from 'react';
import Slider from "react-slick"

// custom hook
import useWindowSize from '../../../Hooks/useWindowSize'

// CSS
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css";

// const tabsData = [
// 	{
// 		id: 1,
// 		tabTitle: "Titulo 1",
// 		tabContent:
// 			<div>
// 				<h2>Ejemplo 1</h2>
// 			</div>
// 	},
// 	{
// 		id: 2,
// 		tabTitle: "Titulo 2",
// 		tabContent:
// 			<div>
// 				<h2>Ejemplo 2</h2>
// 			</div>
// 	},
// 	{
// 		id: 3,
// 		tabTitle: "Titulo 3",
// 		tabContent:
// 			<div>
// 				<h2>Ejemplo 3</h2>
// 			</div>
// 	}
// ]

// class SliderTabs extends Component{

// 	constructor(props) {
// 		super(props)
// 		this.state = {
// 			selectedTab: 'tab-1',
// 			windowWidth: window.innerWidth,
// 			slideIndex: 0,
// 		};
// 		this.handleResize = this.handleResize.bind(this)
// 	}

// 	handleResize() {
// 		this.setState({
// 			windowWidth: window.innerWidth
// 		})
// 	};

// 	componentDidMount() {
// 		window.addEventListener("resize", this.handleResize);
// 	}

// 	componentWillUnmount() {
// 		window.addEventListener("resize", this.handleResize);
// 	} 

// 	render() {

// 		//Window Resize
// 		const { windowWidth } = this.state; 

// 		//Selected Tab
// 		const { selectedTab } = this.state;

// 		//Selected Index Slider
// 		const { slideIndex } = this.state;

// 		const sliderSettings = {
// 			autoplay: false,
// 			autoplaySpeed: 7000,
// 			speed: 600,
// 			slidesToShow: 1,
// 			slidesToScroll: 1,
// 			initialSlide: slideIndex,
// 			dots: false,
// 			arrows: true,
// 			centerMode: true,
// 			centerPadding: '90px',
// 			focusOnSelect: true,
// 			mobileFirst: true,
// 			afterChange: (current) =>
// 				this.setState(({ slideIndex: current, selectedTab: "tab-" + (current + 1) }))
// 		};

// 		return (

// 			<Fragment>
// 				<div className='sliderTabs'>
// 					<div className='sliderTabsMenu'>
// 						{
// 							tabsData.length > 2
// 								?
// 									windowWidth < 640
// 										?
// 											<Slider {...sliderSettings}>
// 												{
// 													tabsData.map((tab, index) => (
// 														<div key={index} className={`stmElement ${selectedTab === `tab-${index}` ? "active" : ""}`} data-tab={`tab-${index}`} onClick={ (e) => this.setState({ selectedTab: e.currentTarget.getAttribute("data-tab") }) }>{tab.tabTitle}</div>
// 													))
// 												}
// 											</Slider>
// 										:
// 											tabsData.map((tab, index) => (
// 												<div key={index} className={`stmElement ${selectedTab === `tab-${index}` ? "active" : ""}`} data-tab={`tab-${index}`} onClick={ (e) => this.setState({ selectedTab: e.currentTarget.getAttribute("data-tab"), slideIndex: index }) }>{tab.tabTitle}</div>
// 											))
// 								:
// 									<span>No se puede generar el slider</span>
// 						}
// 					</div>
// 					<div className="sliderTabsView" id={selectedTab}>
// 						{
// 							tabsData[selectedTab.slice(4) - 1].tabContent
// 						}
// 					</div>
// 				</div>
// 			</Fragment>

// 		)

// 	}
// }
// SliderTabs.propTypes = {
// 	id: PropTypes.number,
// 	tabTitle: PropTypes.string,
// 	tabContent: Children
// }

function SliderTabs2 (props) {

	// const {} = props

	//Selected Tab
	const [selectedTab, setSelectedTab] = useState('tab-0')
	//Selected Index Slider
	const [slideIndex, setSliderIndex] = useState(0)
	
	const [tabsData] = useState(props.tabsData)
	const [dataCPlanes] = useState(props.dataCPlanes)
	
	function savePreFilter(e, filterOption, FilterId) {
        if (filterOption !== undefined || FilterId !== undefined) {
			console.log("savePreFilter:::", filterOption, FilterId, e, e.target)
            localStorage.setItem('preFilter', "filterOption:" + filterOption + ",FilterId:" + FilterId)
        }
    }
	
	const [sliderSettings, setSliderSettings ] = useState( {
		autoplay: false,
		autoplaySpeed: 7000,
		speed: 600,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: slideIndex,
		dots: false,
		arrows: true,
		centerMode: true,
		centerPadding: '90px',
		focusOnSelect: true,
		mobileFirst: true,
		afterChange: (current) =>{
			setSliderIndex(current)
			setSelectedTab("tab-" + current)
		}

	})

	const {
		width: windowWidth,
	}	= useWindowSize()

	useEffect(()=>{
		console.log(windowWidth)
	})
	useEffect(()=>{
		console.log(tabsData)
		console.log(tabsData.length)
		console.log(tabsData.length > 2)
	})
	useEffect(()=>{
		setSliderSettings({
			autoplay: false,
			autoplaySpeed: 7000,
			speed: 600,
			slidesToShow: 1,
			slidesToScroll: 1,
			initialSlide: slideIndex,
			dots: false,
			arrows: true,
			centerMode: true,
			centerPadding: '90px',
			focusOnSelect: true,
			mobileFirst: true,
			afterChange: (current) =>{
				setSliderIndex(current)
				setSelectedTab("tab-" + current)
			}
		})
	}, [slideIndex])
	
	let TXT_BOTON_FOOTER = dataCPlanes.filter((plan, index) => {
        if (index === parseInt(selectedTab.split("-")[1]) ) {
            console.log("tabSelected:-----", plan?.planesDestacados)
            return plan
        } else {
            return null
        }
        // return plan.nameTab === tabSelected.title;
    })

	useEffect(()=>{
		console.log("selectedTab from SliderTabs: " + selectedTab , "slideIndex: " + slideIndex, "SliderSet: " , sliderSettings)
		console.log("TXT_BOTON_FOOTER", TXT_BOTON_FOOTER)
	})

	return(
		<Fragment>
		<>
			<div className='sliderTabs'>
			     {
						tabsData.length >1 ?

				<div className='sliderTabsMenu'>

					{
						tabsData.length > 2
							?
								windowWidth < 640
									?
										<Slider {...sliderSettings}>
											{
												tabsData.map((tab, index) => (
													<div key={index} className={`stmElement ${selectedTab === `tab-${index}` ? "active" : ""}`} data-tab={`tab-${index}`}>{tab.tabTitle}</div>
												))
											}
										</Slider>
									:
										tabsData.map((tab, index) => (
											<div key={index} className={`stmElement ${selectedTab === `tab-${index}` ? "active" : ""}`} data-tab={`tab-${index}`} onClick={ (e) => { setSelectedTab(`tab-${index}`); setSliderIndex(index) } }>{tab.tabTitle}</div>
										))
							:
							tabsData.length === 2
								?
								tabsData.map((tab, index) => (
									<div key={index} className={`stmElement ${selectedTab === `tab-${index}` ? "active" : ""}`} data-tab={`tab-${index}`} onClick={ (e) => { setSelectedTab(`tab-${index}`); setSliderIndex(index) } }>{tab.tabTitle}</div>
								))
								: null
					}
				</div>:''
                  }
				<div className="sliderTabsViewplanesDestacados" id={selectedTab}>
					{
						tabsData[selectedTab.slice(4)].tabContent
					}
				</div>
			</div>
			{
				dataCPlanes?.length && TXT_BOTON_FOOTER[0]?.planesDestacados[0].fc_TXT_BTN
					?
					<div className="c08Button">
						<a className="btn btnTransRojoRojo withIco" href={TXT_BOTON_FOOTER[0]?.planesDestacados[0].fc_URL_PLANES ? TXT_BOTON_FOOTER[0]?.planesDestacados[0].fc_URL_PLANES : "#"} onClick={(e) => { savePreFilter(e, TXT_BOTON_FOOTER[0].filterOption, TXT_BOTON_FOOTER[0].FilterId) }}>
							<i className="ico-arrow-right"></i>
							<span>
								{
									// dataCPlanes[0]?.planesDestacados[0]?.fc_TXT_BTN
									TXT_BOTON_FOOTER[0]?.planesDestacados[0].fc_TXT_BTN
								}
							</span>
						</a>
					</div>
					: null
			}
		</>
		</Fragment>
	)
}

SliderTabs2.defaultProps = {
	tabsData : [
		{
			tabTitle: "Titulo 1",
			tabContent:
				<div>
					<h2>Ejemplo 1</h2>
				</div>
		},
		{
			tabTitle: "Titulo 2",
			tabContent:
				<div>
					<h2>Ejemplo 2</h2>
				</div>
		},
		{
			tabTitle: "Titulo 3",
			tabContent:
				<div>
					<h2>Ejemplo 3</h2>
				</div>
		}
	]
}



export default SliderTabs2
