export class HttpClient {

    async get( url ) {

        const resp = await fetch( url );
        const data = await resp.json();

        return { data, status: resp.status };
    }

    async post( url ) {

        const resp = await fetch( url, {
            method: "POST"
        });
        const data = await resp.json();

        return { data, status: resp.status };
    }
}