//Config
export const VALUE_APPLICATION_JSON = "application/json";

//Value Local Storage
export const URL_WEB_SERVICE_LOCAL_STORAGE = localStorage.getItem('url_detalle_default') ? localStorage.getItem('url_detalle_default') : "";

//Input message validation
export const INPUT_MESSAGE_REQUERID = 'Complete este campo'
export const EMAIL_MESSAGE_FORMAT = 'El formato de correo es inválido'
export const MESSAGE_TOO_SHORT = 'El mínimo de caracteres debe ser $value'
export const PATTERN_MESSAGE = 'Ingrese en formato de'//'Utilice un formato que coincida con el solicitado'
export const MESSAGE_TOO_LONG = 'El máximo de caracteres para el campo es de $value'
export const MESSAGE_RANGE_MAX = 'El rango máximo para el campo es de $value'
export const MESSAGE_RANGE_MIN = 'El rango minimo para el campo es de $value'
export const MESSAGE_STEP = 'El valor ingresado debe de ser múltiplo del $value'
export const MESSAGE_INVALID = 'El valor ingresado no es válido'
export const MESSAGE_TYPE_INVALID = 'El tipo de dato no es válido'
export const MESSAGE_BAD_INPUT = 'Valor no válido o no puede ser convertido'
export const PHONE_NUMBER_CONTACT_INVALID = 'Teléfono inválido'
export const PHONE_NUMBER_INVALID = 'Teléfono inválido'
export const NUMBER_CARD_INVALID = 'Número de tarjeta inválido'
export const SELECT_ACCOUNT = 'Agregue una cuenta'
export const SELECT_SERVICE_NUMBER = 'Agregue un número de servicio'
export const CITIZEN_INVALID = 'Documento inválido'
export const PASSPORT_INVALID = 'Pasaporte inválido'
export const RNC_INVALID = 'RNC inválido'