import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export const InputSelectType = {
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
}
// export const InputSelectTheme= {
//     LIGHT: '',
//     DARK: 'inputCustomDark',
//     INFO: 'inputCustomInfo',
// }

export const InputSelectColor = {
    DEFAULT: '',
    INFO: 'inputCustomInfo',

}

const InputSelect = React.forwardRef ((props, ref) => {
    const {
        name,
        type,
        // theme,
        color,
        checked,
        text,
        children,
        // onClick,
        onChange,
        className,
        disabled,
        defaultValue,
        register
    } = props
    
    const classProps = classNames(
        type,
        // theme,
        color,
        className,
    )

    const typeInput = () => {
        if(type === InputSelectType.CHECKBOX) return 'checkbox'
        else if(type === InputSelectType.RADIO) return 'radio'
        else return 'checkbox'
    }

    return (
        <label className={classProps}>
            <input 
                ref={ref}
                name={name} 
                type={typeInput()} 
                checked={checked ? checked : null} 
                onChange={onChange}
                // onClick={onClick}
                readOnly
                disabled={disabled}
                defaultValue={defaultValue}
                {...register}
            />
            <span className="checkmark"></span>
            <span>{text}</span>
            {children}
        </label>
    )
})

InputSelect.propTypes = {
    type: PropTypes.string,
    // theme: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    checked: PropTypes.bool,
    text: PropTypes.string,
    children: PropTypes.node,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

InputSelect.defaultProps = {
    // name: '',
    type: InputSelectType.RADIO,
    // theme: InputSelectTheme.LIGHT,
    color: InputSelectColor.DEFAULT,
    className: '',
    // checked: false,
    text: '',
    children: '',
    onChange: ()=>{},
    disabled: false,
    defaultValue: null,
    register: [],
}

export default InputSelect
