import React, { memo, useEffect, useState,  forwardRef, useImperativeHandle } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
// import Scrollbar from 'react-custom-scrollbars'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import classNames from 'classnames'

// custom hook
import useWindowSize from '../../../Hooks/useWindowSize'

const modalRoot = document.getElementById('modal-root');

export const ModalSize = {
	XS: 'MoldalMolWrapXs',
	SMALL_2: 'MoldalMolWrapSmall2',
	SMALL: 'MoldalMolWrapSmall',
	MEDIUM: 'MoldalMolWrapMedium',
	MEDIUM_2: 'MoldalMolWrapMedium2',
	MEDIUM_3: 'MoldalMolWrapMedium3',
	BIG: 'MoldalMolWrapBig',
	FULLWIDHT: 'MoldalMolWrapFullWidth',
}

const ModalComponent = forwardRef( (props, ref) =>{
	const {
		children,
		// display,
		size,
		onCloseCallback,
	} = props
	
	const [display, setDisplay] = useState(false)
	// const [height, setHeight] = useState(0)
	const {
		// width: widthWindow,
		height: heightWindow,
	}	= useWindowSize()

	// Funciones que se envian al ref que se recibe del componente padre
	// y en el se puede acceder a ellas a traves de elementRef.current.open(), elementRef.current.close()
	// siendo 'elementRef' el nombre de la ref que se colocó en el padre
	useImperativeHandle(ref, ()=>{
		return {
			open: () => OpenModal(),
			close: () => CloseModal(),
		}
	})

	const OpenModal = () => {
		setDisplay(true)
	}
	
	const CloseModal = () => {
		setDisplay(false)
		onCloseCallback()
	}

	const escFunction = (event) =>{
		if(event.keyCode === 27) {
		  	//Do whatever when esc is pressed
			CloseModal()
		}
    }

    // when press esc key
	useEffect(() => {
		document.addEventListener("keydown", escFunction, false);
		return () => {
			document.removeEventListener("keydown", escFunction, false);
		}
	})

	const classProps = classNames(
		'MoldalMolWrap',
		size,
	)
	
	if(!display) return null
	else return ReactDOM.createPortal(
		<div className='MoldalMolMain'>
			<div className={classProps}>
				<SimpleBar
					style={{ maxHeight: heightWindow }}
				>

					<i className="MoldalMolClose ico-x" onClick={CloseModal} aria-label='Cerrar modal'></i>
					<div className='MoldalMolCont' >
						{children}
					</div>
				</SimpleBar>
				{/* <Scrollbar 
					style={{ width: '100%' }}
					className='MoldalMolScrollbar'
					autoHeight
					autoHeightMin={20}
					autoHeightMax={heightWindow}
					renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
					// renderTrackVertical={props => <div {...props} style={{display: 'none'}} className="track-vertical"/>}
				> */}
				{/* </Scrollbar> */}
			</div>
		</div>
		, modalRoot
	)
		
	
})

ModalComponent.propTypes = {
	display: PropTypes.bool,
	size: PropTypes.oneOf([
		ModalSize.SMALL,
		ModalSize.SMALL_2,
		ModalSize.MEDIUM,
		ModalSize.MEDIUM_2,
		ModalSize.MEDIUM_3,
		ModalSize.BIG,
		ModalSize.FULLWIDHT,
	]),
}

ModalComponent.defaultProps = {
	display: false,
	size: ModalSize.SMALL,
	onCloseCallback: ()=>{}
}

export default memo(ModalComponent)
