import React, { useState, useRef, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import useWindowSize from '../../../../Hooks/useWindowSize'

// Mol
import Modal, { ModalSize } from '../../Modal'
import MChannelsPartModal from '../../Modal/Part/MChannels'
import MServiceHomePartModal from '../../Modal/Part/MServiceHome'
import MSolicitarPartModal from '../../Modal/Part/MSolicitar'
import MModCompraPartModal from '../../Modal/Part/MModCompra'
import { useMemo } from 'react'

// import InputSelect, { InputSelectType } from '../../../Atoms/InputSelect'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

function General2PlanCardMol(props) {
    const {
        title,
        titlestyle,
        name,
        // changeCompare,
        fi_PLAN,
        // fi_ID_TELEFONIA,
        // fi_ID_TELEVISION,
        // fi_ID_INTERNET,
        // fi_CATEGORIA,
        // fc_TIPO_DISPONIBILIDAD,
        // fc_TEXTO_CAJA,
        // fc_COLOR_CAJA,
        // fc_NOMBRE,
        fc_DESCRIPCION,
        fi_NUMERO_CARACTERISTICAS_MOSTRAR,
        fc_TITULO_CARACTERISTICAS_AGRUPADAS,
        fi_PRECIO_PLAN,
        // fi_PRECIO_TELEFONIA_ENPS,
        // fi_PRECIO_TELEVISION_ESPN,
        // fi_PRECIO_INTERNET_ESPS,
        // fc_COLOR_PRECIO,
        fc_ETIQUETA_PRECIO_TACHADO,
        fc_PRECIO_TACHADO,
        fc_NOTA_DEBAJO_PRECIO,
        fc_TEXTO_DETALLE,
        fc_URL_DETALLE,
        fc_TARGET_DETALLE,
        // fi_BAN_DETALLE,
        fc_OPCION_CONTRATACION,
        fc_TEXTO_CONTRATAR,
        fc_URL_CONTRATAR,
        fc_TARGET_CONTRATAR,
        // fi_BAN_CONTRATAR,
        // fi_ORDEN,
        // fi_ACTIVO,
        // fi_ID_DASHBOARD,
        // fi_BAN_ENPN,
        // fi_BAN_ENPS,
        // fi_BAN_ESPN,
        // fi_BAN_ESPS,
        // fc_ETIQUETA_PROMOCION_ATP,
        // fi_PRECIO_AHORRO,
        fc_ETIQUETA_PERIODO,
        fc_TITULO_PROMO,
        Caracteristicas,
        caracteristicasPromocion,
        Acordeon,
        // keysPlanCompare,
        lstPlanContratacionxPlan,
        Discount,
        fi_INCLUIR_SECCION,
        fc_ETIQUETA_ROJA,
        fc_TITULO_SECCION,
        fc_DESCRIPCION_SECCION,
        fc_TEXTO_BOTON,
        fc_TARGET_BOTON,
        fc_URL_BOTON,
        fc_DESCRIPCION_OFERTA,
        fc_PLAN_ICONO,
        fc_NOTA_DETALLE_PROMOCION,
        fi_SERVICIO,
        dataChannelsBk,
        dataPlanesBk,
        dataFranjasBk,
    } = props
    let newAcordeon = Acordeon.map((item, index) => {
        if (!item?.open) {
            item.open = false
        }
        return item;
    })

    const cardPricesRef = useRef()

    const {
        // height: heightWindow,
        width: widthWindow,
    } = useWindowSize()

    const ajustarCard = function (className) {
        var altura, i, maxHeight, total;
        var els = document.getElementsByClassName(className)

        // Limpia todos los height
        Array.prototype.forEach.call(els, function (el) {
            el.style.height = ''
        });

        // Se optienen todos los height
        altura = [];
        total = els.length - 1
        i = 0;
        while (i <= total) {
            altura.push(els[i].offsetHeight);
            i++;
        }
        // Se optiene la height maxima entre todas las tarjetas
        maxHeight = Math.max.apply(null, altura);

        // Se aplica a todos la height
        Array.prototype.forEach.call(els, function (el) {
            // console.log(el.offsetHeight);
            el.style.height = maxHeight + 'px'
        });
    }

    // ajusta siempre que cambia
    useEffect(() => {
        ajustarCard('cPlanV2Head')
        ajustarCard('cPlanV2Prices')
        ajustarCard('cPlanV2Promo')
        ajustarCard('cPlanV2ContentDetails')

    })

    useEffect(() => {
        ajustarCard('cPlanV2Head')
        ajustarCard('cPlanV2Prices')
        ajustarCard('cPlanV2Promo')
        ajustarCard('cPlanV2ContentDetails')
    }, [widthWindow])

    const modalServiceRef = useRef()
    // const modalRequestRef = useRef()
    const modalModCompraRef = useRef()
    const openModalService = () => modalServiceRef.current.open()
    // const openModalRequest = () => modalRequestRef.current.open()
    const openModalModCompra = () => modalModCompraRef.current.open()
    // const closeModalModCompra = () => modalModCompraRef.current.close()

    const onClickSeeMore = () => {
        console.log('Conoce más')
        openModalService()
    }
    const onClickRequest = () => {
        console.log('Contratar')
        openModalModCompra()
    }

    const modalChannelsRef = useRef()
	const openModalChannels = () => modalChannelsRef.current.open()
	const closeModalChannels = () => modalChannelsRef.current.close()

    const onClickChangeChannels = () => {
		openModalChannels()
	}
	const onClickChangeChannelsClose = () => {
		closeModalChannels()
	}


    // const toChecked = keysPlanCompare.filter(function (keyData) {
    //     if (keyData === fi_PLAN) {
    //         return true
    //     } else {
    //         return false
    //     }
    // })

    const searchCharacteristics = (search, TypeFlag = false, Caracteristicas) => {
        let urlModChaCond = 0;
        if (TypeFlag) {
            const data = Caracteristicas?.length ? Caracteristicas : [];
            const newCaractData = data.filter(function (CaracData) {
                let CaracDESCRIPCION = []

                CaracDESCRIPCION = CaracData.fc_DESCRIPCION

                return CaracDESCRIPCION.indexOf(search) > -1
            })
            // console.log("to search---------- TypeFlag:", TypeFlag, search, newCaractData, newCaractData.length)
            return newCaractData
        } else {
            const data = Caracteristicas?.length ? Caracteristicas : [];
            const newCaractData = data.filter(function (CaracData) {
                let CaracDESCRIPCION = []

                CaracDESCRIPCION = CaracData.fc_DESCRIPCION

                return CaracDESCRIPCION.indexOf(search) === -1
            })
            let CaractCheckChannel = newCaractData
            CaractCheckChannel.forEach((c, index)=>{
        
                if(c.fc_TITULO.toLowerCase().indexOf("canal")>-1 && urlModChaCond === 0){
                    c.fc_VALOR = c.fc_VALOR + ''
                    urlModChaCond = 1
                }
            })
            // console.log("CaractCheckChannel", CaractCheckChannel)
            // console.log("to search---------- TypeFlag:", TypeFlag, search, newCaractData, newCaractData.length)
            return newCaractData
        }
    }
    const getChannesByPlanId = async (IdPlan) => {
        let channels = []

        let planCanalIncludes = []
        dataPlanesBk.forEach((dp, index) =>{
			let aFilt = dp.planPlanes.filter(pa => pa.fi_plan === IdPlan)
            aFilt.length ? 
                !planCanalIncludes.includes(dp.fi_gc_plan) ? planCanalIncludes.push(dp.fi_gc_plan) : null
                
            : null
		});

        dataChannelsBk.forEach((c, index) =>{
			let aFilt = c.planCanal.filter(pc => planCanalIncludes.includes(pc.fi_gc_plan))
            aFilt.length ?
                channels.push(c)
            : null
		});
        let CateIncludes = []
        let FranjasInclude = []
        channels.forEach((c, index)=>{
            if(!CateIncludes.includes(c.fi_categoria)){
                CateIncludes.push(c.fi_categoria)
            }
            if(!FranjasInclude.includes(c.fi_franja)){
                FranjasInclude.push(c.fi_franja)
            }
        })
        let listFranjas = []
        let listFranjasFiltered = {}
		dataFranjasBk.map((item, index) =>{
			let list = item.filter(c => CateIncludes.includes(c.fi_categoria))
			if (list.length){
				listFranjas.push(list);
			}
			return list;
		})
        
        listFranjas.map((item, index) =>{
			let list = item.filter(c => FranjasInclude.includes(c.fi_franja))
			if (list.length){
				listFranjasFiltered = list;
			}
			return list;
		})
		console.log("getChannesByPlanId by category=", CateIncludes, FranjasInclude, listFranjas, listFranjasFiltered)
		setDataFranjas(listFranjasFiltered);
        
        // console.log("getChannesByPlanId channels General2", channels)
        setDataChannels(channels)
    }
    useEffect(() => {
        getChannesByPlanId(fi_PLAN)
        // eslint-disable-next-line
    }, [])
    
    const [detailsCond, setDetailsCond] = useState(false)
    const [dataChannels, setDataChannels] = useState([])
    const [dataFranjas, setDataFranjas] = useState([])
    let CharactBlock = searchCharacteristics('Tipo Footer', false, Caracteristicas)
    let CharactFooter = searchCharacteristics('Tipo Footer', true, Caracteristicas)
    const symbolPrice = fi_SERVICIO === 3  ? "Q" : "Q";
    // const displayCompare = localStorage.getItem('fi_servicio')  === "3"  ? "none" : "none";
    let flagModChannel = localStorage.getItem('id_vigencia') === "CHN" ? true : false;

    const promocionContent = useMemo(() => {
        if (!caracteristicasPromocion?.length) return null;

        return caracteristicasPromocion.map((caract, index) => (
            caract?.fc_TIPO_VALOR_CARACTERISTICA.includes("IMG") ?
                <picture key={uuid()}>
                    <source media="(max-width: 640px)" data-srcset={caract?.fc_VALOR.split(".")[0] + ".webp"} type="image/webp" />
                    <source media="(max-width: 640px)" data-srcset={caract?.fc_VALOR} type={"image/" + caract?.fc_VALOR.split(".")[1]} />
                    <source media="(min-width: 640px)" data-srcset={caract?.fc_VALOR.split(".")[0] + ".webp"} type="image/webp" />
                    <source media="(min-width: 640px)" data-srcset={caract?.fc_VALOR} type={"image/" + caract?.fc_VALOR.split(".")[1]} />
                    <img className="lazyload" data-src={caract?.fc_VALOR} alt={caract?.fc_ALT_IMG} width="75" height="25" />
                </picture>
                :
                <i key={uuid()} className={caract?.fc_VALOR}></i>
        ));
    }, [caracteristicasPromocion]);
    return (
        <>
            <div className={'cPlanV2Wrap ' + (titlestyle ? titlestyle : 'amarillo')}>
                <div className="cPlanV2">
                    {
                        title
                            ?
                            <div className="cPlanV2Flag">
                                <p>{title}</p>
                            </div>
                            :
                            null
                    }
                    <div className="cPlanV2Head">
                        <div className="cPlanV2Icon">
                            {
                                fc_PLAN_ICONO
                                    ?
                                    <i className={fc_PLAN_ICONO}></i>
                                    : null
                            }
                        </div>
                        <div className="cPlanV2HeadCont">
                            <h3 className="h3">{name}
                                 {
                                    fc_DESCRIPCION ?
                                        <p>
                                            {fc_DESCRIPCION}
                                        </p>
                                        : null
                                }
                            </h3>
                            {/* <div className="cPlanV2HeadInf"> */}
                                {/* <p>{name}</p> */}
                               
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="cPlanV2Prices" ref={cardPricesRef}>
                        <div className="cPlanV2PricesTitle">
                            {
                                fc_DESCRIPCION_OFERTA
                                    ?
                                    <p>{fc_DESCRIPCION_OFERTA}</p>

                                    : null
                            }
                        </div>
                        <div className="cPlanV2PricesPrice">
                            {/* <p>$25.990</p> */}
                            {
                                fi_PRECIO_PLAN ?
                                    <p>
                                        {symbolPrice}{fi_PRECIO_PLAN}
                                    </p>
                                    : null
                            }
                        </div>
                        <div className="cPlanV2PricesTime">
                            {/* <p>mensual por 11 meses</p> */}
                            {
                                fc_ETIQUETA_PERIODO ?
                                    <p>
                                        {fc_ETIQUETA_PERIODO}
                                    </p>
                                    : null
                            }
                        </div>
                        <div className="cPlanV2PricesNote">
                            {/* <p>cargo fijo normal: <del>$37.990</del> mensual</p> */}
                            {
                                fc_ETIQUETA_PRECIO_TACHADO && fc_PRECIO_TACHADO ?

                                    <p>{fc_ETIQUETA_PRECIO_TACHADO}: {symbolPrice} <del>{fc_PRECIO_TACHADO}</del></p>

                                    : null
                            }
                        </div>

                        {
                            fc_NOTA_DEBAJO_PRECIO ?
                                <div className="cPlanV2PricesPlaque">
                                    <p>
                                        {fc_NOTA_DEBAJO_PRECIO}
                                    </p>
                                </div>
                                : null
                        }
                        {
                            Discount != null ?
                                <div className="cPlanV2Tag">
                                    <picture>
                                        <source media="(max-width: 640px)" data-srcset="/portal/gt/recursos_tema/assets/img/cardPlanV2Disc.webp" type="image/webp" />
                                        <source media="(max-width: 640px)" data-srcset="/portal/gt/recursos_tema/assets/img/cardPlanV2Disc.png" type="image/jpeg" />
                                        <source media="(min-width: 640px)" data-srcset="/portal/gt/recursos_tema/assets/img/cardPlanV2Disc.webp" type="image/webp" />
                                        <source media="(min-width: 640px)" data-srcset="/portal/gt/recursos_tema/assets/img/cardPlanV2Disc.png" type="image/jpeg" />
                                        <img className="lazyload" data-src="/portal/gt/recursos_tema/assets/img/cardPlanV2Disc.png" alt="" width="100" height="55" />
                                    </picture>

                                    <div className='cPlanV2TagCont'>
                                        <div className="cPlanV2TagNumber">
                                            <p>{Discount}</p>
                                        </div>
                                        <div className='cPlanV2TagPor'>
                                            <p className='disc'>%</p>
                                            <p className='note'>DCTO</p>
                                        </div>
                                    </div>

                                </div>
                                :
                                <div className="cPlanV2Tag"></div>
                        }

                    </div>
                    {
                        fc_TITULO_PROMO || caracteristicasPromocion?.length
                            ?
                            <div className="cPlanV2Promo">
                                <p>{fc_TITULO_PROMO ? fc_TITULO_PROMO : null}</p>
                                <div className="cPlanV2PromoItems">
                                {promocionContent}
                                </div>
                            </div>
                            : null
                    }
                    <div className={`cPlanV2Content cPlanV2ContentMore ${detailsCond ? 'openDetails' : ''}`}>
                        <div className="cPlanV2ContentOpen" onClick={() => { setDetailsCond(true) }}>
                            <p>Ver detalle {fi_SERVICIO === 2 ? " del paquete" :" de plan"}</p>
                            <i className="ico-chevron-down"></i>
                        </div>

                        <div className="cPlanV2ContentDetails">
                            {
                                CharactBlock ?
                                    CharactBlock.map((caract, index) => (
                                        caract.fc_VALOR.includes("logo") ?
                                            <dl key={uuid()} className={`cPlanV2DL ${caract?.fi_DIVISION === 1 ? ' catDivCPlan ' : '' }`} style={(index + 1) > fi_NUMERO_CARACTERISTICAS_MOSTRAR ? {} : {}}>

                                                <dt>
                                                    <span className={`${caract.fc_VALOR}`}></span>
                                                </dt>
                                                <dd>{caract.fc_TITULO}</dd>
                                            </dl>
                                            :
                                            <dl key={uuid()} className={`cPlanV2DL ${caract?.fi_DIVISION === 1 ? ' catDivCPlan ' : '' } ${caract.fc_CLASE_ICONO  ? 'iconEle' : ''}`} style={(index + 1) > fi_NUMERO_CARACTERISTICAS_MOSTRAR ? {} : {}}>

                                                {
                                                    caract.fc_CLASE_ICONO
                                                        ?
                                                        <dt>
                                                            {
                                                                //caract.fc_DESCRIPCION.includes("Redes Sociales")
                                                                //? null   Si es redes sociales
                                                                //:
                                                                 <i className={caract.fc_CLASE_ICONO}></i>
                                                            }
                                                            <span>{caract.fc_TITULO}</span>
                                                        </dt>
                                                        :
                                                        <dt>
                                                            {caract.fc_TITULO}
                                                        </dt>
                                                }

                                                {
                                                    caract.fc_DESCRIPCION.includes("Redes Sociales")
                                                        ?
                                                        <dd className="cPlanRedS">
                                                            <ul>
                                                                {
                                                                    caract.fc_VALOR.split("|").map((icoRed, index) => (
                                                                        <li key={uuid()}>
                                                                            <span>
                                                                                <i className={icoRed} />
                                                                            </span>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </dd>
                                                        :
                                                        <dd>
                                                            {
                                                                dataChannels.length > 0 && caract.fc_TITULO.toLowerCase().indexOf("canal") >-1 && 
                                                                caract.fc_VALOR.indexOf("SETURLMODCH") > -1  && flagModChannel ?
                                                                <>
                                                                    <span className="ddExtra">{caract.fc_VALOR.replaceAll("SETURLMODCH", "")}</span>
                                                                    <a href="#!" className="textLink" onClick={()=>{onClickChangeChannels()}}>Ver canales</a>
                                                                </>
                                                                :
                                                                caract.fc_VALOR.replaceAll("SETURLMODCH", "")
                                                            }
                                                        </dd>
                                                }
                                            </dl>
                                    ))

                                    : null
                            }
                            {
                                CharactFooter ?
                                    CharactFooter.map((caract, index) => (
                                        <div key={uuid()} className="cPlanIconBlock" style={(index + 1) > fi_NUMERO_CARACTERISTICAS_MOSTRAR ? {} : {}}>
                                            <span className="iconCircle">
                                                {
                                                    caract.fc_CLASE_ICONO ?
                                                        <i className={caract.fc_CLASE_ICONO}></i>
                                                        : null
                                                }

                                            </span>
                                            <p>
                                                <strong> {caract.fc_TITULO}</strong>
                                                <span> {caract.fc_VALOR}</span>
                                            </p>
                                        </div>
                                    ))
                                    : null
                            }
                            <dl className="cPlanV2DL">
                                <div className="cPlanV2DLAction">
                                    {
                                        fc_TEXTO_DETALLE ?
                                            fc_URL_DETALLE !== "$URL_DEFAULT_DETALLE$"
                                                ?
                                                <a href={fc_URL_DETALLE} target={fc_TARGET_DETALLE ? fc_TARGET_DETALLE : "_self"}>
                                                    {fc_TEXTO_DETALLE}
                                                </a>
                                                :
                                                <a href="#!" target={fc_TARGET_DETALLE ? fc_TARGET_DETALLE : "_self"} onClick={() => onClickSeeMore()}>
                                                    {fc_TEXTO_DETALLE}
                                                </a>
                                            : null
                                    }
                                </div>
                            </dl>
                        </div>

                        <div className="cPlanV2ContentClose" onClick={() => { setDetailsCond(false) }}>
                            <p>Ocultar</p>
                            <i className="ico-chevron-up"></i>
                        </div>
                    </div>
                    <div className="cPlanV2Actions">
                        {
                            fc_TEXTO_CONTRATAR ?
                                fc_OPCION_CONTRATACION === "URL" ?
                                    <a className="btn btnPrimario" href={fc_URL_CONTRATAR} target={fc_TARGET_CONTRATAR ? fc_TARGET_CONTRATAR : '_self'}>
                                        {fc_TEXTO_CONTRATAR}
                                    </a>
                                    :
                                    <a className="btn btnPrimario" href="#!" onClick={() => onClickRequest()} target={'_self'}>
                                        {fc_TEXTO_CONTRATAR}
                                    </a>
                                : null
                        }
                    </div>
                    
                </div>
                {
                    fi_INCLUIR_SECCION === 1
                        ?
                        <div className="cPlanV2Offer cPlanV2OfferShow">
                            <p className="cPlanV2OfferLeft">{fc_ETIQUETA_ROJA}</p>
                            <div className="cPlanV2OfferCont">
                                <p>{fc_TITULO_SECCION}</p>
                                <p>{fc_DESCRIPCION_SECCION}</p>
                                <div className="cPlanV2OfferAction">
                                    <a href={fc_URL_BOTON} target={fc_TARGET_BOTON ? fc_TARGET_BOTON : '_self'}>
                                        {fc_TEXTO_BOTON}
                                        <i className="ico-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
            <Modal 
				ref={modalChannelsRef}
				size={ModalSize.BIG}
			>
				<MChannelsPartModal
					onClose={onClickChangeChannelsClose}
					dataChannels={dataChannels}
					planName={name}
                    dataFranjas={dataFranjas}
				/>
			</Modal>
            <Modal
                ref={modalServiceRef}
                size={ModalSize.MEDIUM_2}
            >
                <MServiceHomePartModal
                    asks={newAcordeon}
                    Caracteristicas={Caracteristicas}
                    title={title}
                    name={name}
                    fc_DESCRIPCION={fc_DESCRIPCION}
                    fi_PRECIO_PLAN={fi_PRECIO_PLAN}
                    fc_ETIQUETA_PERIODO={fc_ETIQUETA_PERIODO}
                    titlestyle={titlestyle}
                    fc_TITULO_PROMO={fc_TITULO_PROMO}
                    caracteristicasPromocion={caracteristicasPromocion}
                    fc_NOTA_DETALLE_PROMOCION={fc_NOTA_DETALLE_PROMOCION}
                    symbolPrice={symbolPrice}
                    fc_TITULO_CARACTERISTICAS_AGRUPADAS={fc_TITULO_CARACTERISTICAS_AGRUPADAS}
                />
            </Modal>
            {
                fc_OPCION_CONTRATACION === "REN"
                    ?
                    <Modal
                        ref={modalModCompraRef}
                        size={ModalSize.MEDIUM_3}
                    >
                        <MModCompraPartModal
                            title={title}
                            name={name}
                            fc_DESCRIPCION={fc_DESCRIPCION}
                            fi_PRECIO_PLAN={fi_PRECIO_PLAN}
                            fc_ETIQUETA_PERIODO={fc_ETIQUETA_PERIODO}
                            fc_DESCRIPCION_OFERTA={fc_DESCRIPCION_OFERTA}
                            lstPlanContratacionxPlan={lstPlanContratacionxPlan}
                            symbolPrice={symbolPrice}
                            Caracteristicas={Caracteristicas}
                            fc_TITULO_PROMO={fc_TITULO_PROMO}
                            caracteristicasPromocion={caracteristicasPromocion}
                        />
                    </Modal>
                    :
                    <Modal
                        ref={modalModCompraRef}
                        size={ModalSize.SMALL}
                    >
                        <MSolicitarPartModal
                            title={title}
                            name={name}
                            fc_DESCRIPCION={fc_DESCRIPCION}
                            fi_PRECIO_PLAN={fi_PRECIO_PLAN}
                            fc_ETIQUETA_PERIODO={fc_ETIQUETA_PERIODO}
                            lstPlanContratacionxPlan={lstPlanContratacionxPlan}
                        />
                    </Modal>
            }
        </>
    )
}

export default General2PlanCardMol
